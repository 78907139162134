document.addEventListener('DOMContentLoaded', function (event) {
    const textArr = ["Slower paced.", "Non-contact.", "Competitive pick-up shinny."];

    const typeWriter = (text, i, callback) => {
        if (i < text.length){
            document.querySelector(".welcome__animation").innerHTML = text.substring(0, i + 1);
            setTimeout(function () {
                typeWriter(text, i + 1, callback)
            }, 100);
        } else if (typeof callback == 'function') {
            setTimeout(callback, 700);
        }
    }

    const loopAnimation = (i) => {
        if(typeof textArr[i] == 'undefined') {
            setTimeout(function () {
                loopAnimation(0);
            }, 5000);
        }
        if (i < textArr[i].length) {
            typeWriter(textArr[i], 0, function () {
                loopAnimation(i + 1);
            });
        }
    }

    loopAnimation(0);

});

const fixedHeader = () => {
        let y = window.scrollY;
        if(y > 0){
            document.querySelector('.menu').classList.add('menu--active');
            document.querySelector('.btn-signup').classList.add('btn-signup--active');
            document.querySelector('.mobile-menu').classList.add('mobile-menu--active');
            document.querySelector('.menu__mobile-button').classList.add('menu__mobile-button--active');
            document.querySelector('.menu__logo').classList.remove('menu__logo--light');
            document.querySelector('.menu__logo').classList.add('menu__logo--dark');
            let menuItems = document.querySelectorAll('.menu__link');
            menuItems.forEach((item) => {
                item.classList.add('menu__link--dark');
            });
        }else{
            document.querySelector('.menu').classList.remove('menu--active');
            document.querySelector('.btn-signup').classList.remove('btn-signup--active');
            document.querySelector('.mobile-menu').classList.remove('mobile-menu--active');
            document.querySelector('.menu__mobile-button').classList.remove('menu__mobile-button--active');
            document.querySelector('.menu__logo').classList.add('menu__logo--light');
            document.querySelector('.menu__logo').classList.remove('menu__logo--dark');
            let menuItems = document.querySelectorAll('.menu__link');
            menuItems.forEach((item) => {
                item.classList.remove('menu__link--dark');
            });

        }
};

window.addEventListener('scroll', function() {
    fixedHeader();
});

document.querySelector('.menu__mobile-button').addEventListener('click', () => {
    document.querySelector('.mobile-menu').classList.toggle('active');
});
document.querySelector('.mobile-menu__close').addEventListener('click', () => {
    document.querySelector('.mobile-menu').classList.toggle('active');
});

//Close Mobile Menu after click
// $('.mobile-menu__wrapper ul li a').on('click', function () {
//     $('.mobile-menu').removeClass('active');
// });